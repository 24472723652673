import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { trackProductCode } from '../utils/analytics'

import Page from '../components/layout/Page'
import FrontPage from '../components/FrontPage'
import MetaTags from '../components/MetaTags'

import { withTranslation } from '../../i18n'
import { getProductCode } from '../store/nabolag/selectors'
import isServer from '../utils/is-server'

const mapStateToProps = (state) => ({
  productCode: getProductCode(state),
})

// eslint-disable-next-line
class IndexPage extends React.PureComponent {
  static async getInitialProps() {
    return { namespacesRequired: ['common', 'categories', 'error', 'schibsted'] }
  }

  constructor(props) {
    super(props)

    if (!isServer()) {
      const { productCode } = props
      trackProductCode(productCode)
    }
  }

  render() {
    return (
      <Page noMenu>
        <MetaTags title="Digital Nabolagsprofil" />
        <FrontPage />
      </Page>
    )
  }
}

IndexPage.propTypes = {
  productCode: PropTypes.string.isRequired,
}

export default compose(withTranslation(['common']), connect(mapStateToProps))(IndexPage)
