import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import styled, { withTheme } from 'styled-components'

import { H1 } from '../ui/Headings'
import Button from '../ui/Button'
import Input from '../ui/Input'
import { withTranslation } from '../../i18n'

const Wrapper = styled.section`
  padding: ${props => `0 ${props.theme.spacingLarge} ${props.theme.spacingLarge}`};
`

const SubHeading = styled.p`
  margin: 0 0 20px;
  font-size: 16px;
`

const FormWrapper = styled.div`
  width: 100%;
`
const Spacer = styled.div`
  width: 100%;
  height: 20px;
`

class FrontPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: '',
      submitting: false,
    }
  }

  handleInputChange = e => {
    this.setState({
      inputValue: e.target.value,
    })
  }

  handleKeyDown = e => {
    switch (e.key) {
      case 'Enter': {
        e.preventDefault()
        this.handleButtonClick()
        break
      }
      default:
        break
    }
  }

  handleButtonClick = () => {
    const { inputValue } = this.state
    this.setState({
      submitting: true,
    })
    window.location = `/${inputValue}`
  }

  render() {
    const { t } = this.props
    const { submitting, inputValue } = this.state

    return (
      <Wrapper>
        <H1>{t('common:frontpage.title')}</H1>
        <SubHeading>{t('common:frontpage.intro')}</SubHeading>
        <FormWrapper>
          <Input
            type="number"
            aria-label={t('common:frontpage.label')}
            placeholder={t('common:frontpage.placeholder')}
            disabled={submitting}
            onChange={this.handleInputChange}
            onKeyDown={this.handleKeyDown}
            value={inputValue}
          />
          <Spacer />
          <Button disabled={submitting} onClick={this.handleButtonClick}>
            {t('common:frontpage.submit')}
          </Button>
        </FormWrapper>
      </Wrapper>
    )
  }
}

FrontPage.propTypes = {
  t: PropTypes.func.isRequired,
}
FrontPage.defaultProps = {}

export default compose(
  withTranslation(['common']),
  withTheme,
)(FrontPage)
